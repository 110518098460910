import { useState } from "react";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle answer visibility
  };

  return (
    <div className="bg-white">
      <section className="py-24">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mb-16">
            <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
              Frequently asked questions
            </h2>
          </div>
          <div className="accordion-group" data-accordion="default-accordion">
            {/* FAQ 1 */}
            <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(1)}
              >
                <h5 className="font-semibold">Is it legal to download Facebook videos?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 1 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 1 && (
                <div className="accordion-content w-full overflow-hidden ">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Yes, it’s legal to download Facebook videos for personal use. However, distributing or using the content commercially without permission is against Facebook’s terms of service.
                  </p>
                </div>
              )}
            </div>

            {/* FAQ 2 */}
            <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(2)}
              >
                <h5 className="font-semibold">Can I download private Facebook videos?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 2 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 2 && (
                <div className="accordion-content w-full overflow-hidden ">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  No, our tool only works for public videos. Private or restricted content cannot be downloaded.
                  </p>
                </div>
              )}
            </div>

            {/* FAQ 3 */}
            <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(3)}
              >
                <h5 className="font-semibold">Do I need to create an account to use the FB video downloader?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 3 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 3 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  No, our tool is completely free and doesn’t require any registration.
                  </p>
                </div>
              )}
            </div>

           

            {/* FAQ 4 */}
            <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(4)}
              >
                <h5 className="font-semibold">What formats are supported?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 4 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 4 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Our tool supports MP4, MP3, and other popular formats.
                  </p>
                </div>
              )}
            </div>

             {/* FAQ 5 */}
             <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(5)}
              >
                <h5 className="font-semibold">Can I download Facebook reels and stories?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 5 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 5 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Yes, our tool supports downloading Facebook reels and stories.
                  </p>
                </div>
              )}
            </div>

              {/* FAQ 6 */}
              <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(6)}
              >
                <h5 className="font-semibold">Is the Facebook Video Downloader free?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 6 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 6 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Absolutely! Our tool is 100% free to use.
                  </p>
                </div>
              )}
            </div>

             {/* FAQ 7 */}
             <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(7)}
              >
                <h5 className="font-semibold">How do I download Facebook videos in HD quality?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 7 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 7 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Select the HD option before downloading.
                  </p>
                </div>
              )}
            </div>

             {/* FAQ 8 */}
             <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(8)}
              >
                <h5 className="font-semibold">Can I use this tool on my phone?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 8 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 8 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Yes, our tool is compatible with all devices, including smartphones.
                  </p>
                </div>
              )}
            </div>

              {/* FAQ 9*/}
              <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(9)}
              >
                <h5 className="font-semibold">Why is my download not working?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 9 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 9 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Ensure the video URL is correct and the video is public. If the issue persists, contact our support team.
                  </p>
                </div>
              )}
            </div>

             {/* FAQ 10*/}
             <div
              className="accordion border border-solid border-gray-300 p-4 rounded-xl mb-8 lg:p-4"
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleFAQ(10)}
              >
                <h5 className="font-semibold">Is my data safe with this tool?</h5>
                <svg
                  className={`w-6 h-6 text-gray-900 transition duration-500 ${openIndex === 10 ? 'rotate-45' : ''}`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {openIndex === 10 && (
                <div className="accordion-content w-full overflow-hidden">
                  <p className="text-base text-gray-900 font-normal leading-6 text-left">
                  Yes, we prioritize user privacy and do not store or share any data.                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQ;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import Navbar from './components/Navbar';
import Downloader from './components/Downloader';
import Footer from './components/Footer';
import Content from './components/Content';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Disclaimer from './pages/Disclaimer';
import SEO from './components/SEO';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App overflow-hidden">
          <SEO title="Facebook Video Downloader Online - Download Facebook Videos, Reels, and Stories" description="Save your favorite FB reels with just one click. Our tool is the best Facebook Video Downloader available online. Easily download any Facebook video in high quality." />
          <Navbar />
          <Routes>
            <Route path="/" element={<Downloader />} />
            <Route path="/" element={<Content />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/blog" element={<Disclaimer />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
